exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-course-handicap-calculator-js": () => import("./../../../src/pages/course-handicap-calculator.js" /* webpackChunkName: "component---src-pages-course-handicap-calculator-js" */),
  "component---src-pages-golf-handicap-calculator-js": () => import("./../../../src/pages/golf-handicap-calculator.js" /* webpackChunkName: "component---src-pages-golf-handicap-calculator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matches-js": () => import("./../../../src/pages/matches.js" /* webpackChunkName: "component---src-pages-matches-js" */),
  "component---src-pages-past-scores-js": () => import("./../../../src/pages/past-scores.js" /* webpackChunkName: "component---src-pages-past-scores-js" */),
  "component---src-pages-player-scores-js": () => import("./../../../src/pages/player-scores.js" /* webpackChunkName: "component---src-pages-player-scores-js" */),
  "component---src-pages-players-js": () => import("./../../../src/pages/players.js" /* webpackChunkName: "component---src-pages-players-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-scores-2021-js": () => import("./../../../src/pages/scores-2021.js" /* webpackChunkName: "component---src-pages-scores-2021-js" */),
  "component---src-pages-scores-2022-js": () => import("./../../../src/pages/scores-2022.js" /* webpackChunkName: "component---src-pages-scores-2022-js" */),
  "component---src-pages-scores-2023-js": () => import("./../../../src/pages/scores-2023.js" /* webpackChunkName: "component---src-pages-scores-2023-js" */),
  "component---src-pages-scores-2024-js": () => import("./../../../src/pages/scores-2024.js" /* webpackChunkName: "component---src-pages-scores-2024-js" */),
  "component---src-pages-scores-2025-js": () => import("./../../../src/pages/scores-2025.js" /* webpackChunkName: "component---src-pages-scores-2025-js" */),
  "component---src-pages-scores-js": () => import("./../../../src/pages/scores.js" /* webpackChunkName: "component---src-pages-scores-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-team-records-js": () => import("./../../../src/pages/team-records.js" /* webpackChunkName: "component---src-pages-team-records-js" */),
  "component---src-pages-team-scores-js": () => import("./../../../src/pages/team-scores.js" /* webpackChunkName: "component---src-pages-team-scores-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-player-post-js-content-file-path-content-players-blake-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/blake/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-blake-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-cam-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/cam/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-cam-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-chris-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/chris/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-chris-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-craig-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/craig/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-craig-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-curtis-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/curtis/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-curtis-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-dan-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/dan/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-dan-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-derek-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/derek/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-derek-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-dylan-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/dylan/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-dylan-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-evan-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/evan/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-evan-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-gordon-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/gordon/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-gordon-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-jake-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/jake/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-jake-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-matt-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/matt/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-matt-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-rj-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/rj/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-rj-index-md" */),
  "component---src-templates-player-post-js-content-file-path-content-players-travis-index-md": () => import("./../../../src/templates/player-post.js?__contentFilePath=/opt/build/repo/content/players/travis/index.md" /* webpackChunkName: "component---src-templates-player-post-js-content-file-path-content-players-travis-index-md" */)
}

